<template>
  <div class="user-binding">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="user-tabs">
      <el-tab-pane :label="$t('front-epwechat-module-v1-unbound-user')" name="Unbound"></el-tab-pane>
      <el-tab-pane :label="$t('front-epwechat-module-v1-binding-user')" name="Bound"></el-tab-pane>
    </el-tabs>
    <div>
      <!-- 搜索条件 -->
      <div class="user-search">
        <div>
          <el-input
            size="mini"
            placeholder="按姓名、邮箱、手机号查找用户"
            v-model="condition"
            class="input-with-select"
            @change="seachBoundUser"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <el-button size="mini" @click="seachBoundUser">{{ $t('label.tabpage.searchz') }}</el-button>
        </div>
        <div>
          <div v-if="activeName == 'Unbound' && !editAllBtn">
            <el-button size="mini" type="primary" @click="autoMate"
              >{{ $t('front-epwechat-module-v1-auto-mate') }}</el-button
            >
            <el-button size="mini" type="primary" @click="editAllBtn = true"
              >{{ $t('lable.product.pricebooks.editall') }}</el-button
            >
          </div>
          <div v-else-if="activeName == 'Unbound' && editAllBtn">
            <el-button size="mini" type="primary" @click="savebatchBoundEPUser"
              >{{ $t('label.tabpage.save') }}</el-button
            >
            <el-button size="mini" type="primary" @click="cancelbatchBoundEPUser"
              >{{ $t('label.tabpage.cancel') }}</el-button
            >
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <wechat-table
        class="table_height"
        :wechatdata="wechatdata"
        :showStar="true"
        :isShowOpration="true"
        :oprationBtnList="
          activeName == 'Unbound'
            ? !editAllBtn
              ? unboundBtn
              : ''
            : boundBtn
        "
        :editAllBtn="editAllBtn"
        :editInner="editInner"
        :innerData="innerData"
        @AccountBinding="AccountBinding"
        @Save="Save"
        @cancel="cancel"
        @Rebind="Rebind"
        @Unbind="Unbind"
        @inLineEdit="inLineEdit"
        sign="user"
        :page="page"
        @goHomePage="goHomePage"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></wechat-table>
    </div>
    <!-- 解除绑定弹窗 -->
    <el-dialog :visible.sync="dialogUnbind" width="460px">
      <div slot="title" class="dialog-title">
        <i class="el-icon-warning-outline" style="color: #e6a23c"></i>
        <span class="title-text">&nbsp;&nbsp;{{ $t('label.prompt') }}</span>
      </div>
      <div>{{ $t('front-epwechat-module-v1-unbound-desc') }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogUnbind = false">{{ $t('label.tabpage.cancel') }}</el-button>
        <el-button size="mini" type="primary" @click="unboundEPUserData"
          >{{ $t('label.tabpage.ok') }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as EPWeChat from "./api.js"
import wechatTable from "./components/wechatTable.vue"
export default {
  components: {
    wechatTable,
  },
  data() {
    return {
      wechatdata: {
        tabledata:[], //数据
        tablekey:[] //表头
      },
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 100,
      },
      activeName: "Unbound",// 未/已绑定用户
      condition: "", //按姓名、邮箱、手机号查找用户
      editAllBtn: false,//全部编辑
      editInner: false,//行内编辑
      innerData: {},//单行数据
      isShowOpration: true, //是否显示操作列
      // 操作列功能按钮
      unboundBtn: [
        { action: "AccountBinding", label: this.$i18n.t('front-epwechat-module-v1-account-binding'), type: "primary" },
      ],
      boundBtn: [
        { action: "Rebind", label: this.$i18n.t('front-epwechat-module-v1-again-binding'), type: "primary" },
        { action: "Unbind", label: this.$i18n.t('label.weixin.unbind'), type: "primary" },
      ],
      dialogUnbind: false, // 解除绑定弹窗
      rowData:"",//解绑该行数据
      currentBtntext: "",//账号绑定或重新绑定，用于提示成功信息
    };
  },
  mounted() {
    // eslint-disable-next-line no-constant-condition
    this.getBoundUserData("Unbound" ? "0" : "1",this.page);
  },
  methods: {
    /** tabs切换 */
    handleClick(tab) {
      this.editAllBtn = false;//防止在未绑定用户列表编辑全部，已绑定用户列表依旧是编辑状态。
      let status = tab.name == "Unbound" ? "0" : "1";
      this.getBoundUserData(status,this.page);
    },
    /** 账号绑定 */
    AccountBinding(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 保存 */
    Save(row) {
      this.saveboundEPUser(row);
      this.editInner = false
    },
    /** 取消 */
    cancel(){
      this.seachBoundUser()
      this.editInner = false
    },
    /** 用户绑定企业微信 */
    
    saveboundEPUser(data) {
      EPWeChat.boundEPUser({
        loginname: data.loginname,
        cloudccuserid: data.cloudccuserid,
        openid: data.openid,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.seachBoundUser()
          // if(this.currentBtntext === "账号绑定") {
          //   // 绑定成功  label.mobile.bind.success
          //   this.$message.success(this.$i18n.t('label.mobile.bind.success'))
          // }else if(this.currentBtntext === "重新绑定") {
          //   // 重新绑定成功
          //   this.$message.success(this.$i18n.t('c173'))
          // }
          this.$message.success(this.currentBtntext+'成功')
        }else{
          // 登陆失败或其他
          this.$message.warning(res.returnInfo)
        }
      });
    },
    /** 重新绑定 */
    Rebind(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 解除绑定 */
    Unbind(row) {
      this.dialogUnbind = true;
      this.rowData = row;
    },
    /** 解绑企业微信用户 */
    unboundEPUserData(){
      let data = this.rowData;
      EPWeChat.unboundEPUser({
        userid: data.cloudccuserid,
        epuserid: data.openid,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.dialogUnbind = false;
          this.getBoundUserData('1',this.page)
          // 解绑成功
          this.$message.success(this.$i18n.t('c41'))
        }
      });
    },
    /** 自动匹配 */
    autoMate() {
      EPWeChat.autoBoundEPUser().then((res) => {
        if (10000 == res.returnCode) {
          this.getBoundUserData('0',this.page);
          // 自动匹配成功
          this.$message.success(this.$i18n.t('c108'))
        }
      });
    },
    /** 搜索 */
    seachBoundUser() {
      let status = this.activeName == "Unbound" ? "0" : "1";
      this.getBoundUserData(status,this.page);
    },
    /** 查询用户绑定信息 */
    getBoundUserData(value,page) {
      EPWeChat.getBoundUser({
        condition: this.condition,
        bindingstatus: value,
        count: page.pageSize,
        page: page.pageNum
      }).then((res) => {
        let data = res.data;
        if (data.tabledata) {
          data.tabledata.forEach(item => {
            if (item.isusing == 1) {
              item.isusing = this.$i18n.t('label.inusing')
            }else if(item.isusing == 0){
              item.isusing = this.$i18n.t('label.nousing')
            }
          });
          this.wechatdata.tabledata = data.tabledata;
          this.wechatdata.tablekey = data.tablekey;
          this.isShowOpration = true
          // 分页
          this.page.pageNum = data.page
          this.page.pageSize = data.count
          this.page.total = data.totalpage
        }else {
          this.wechatdata.tabledata = []
          this.wechatdata.tablekey = []
          this.isShowOpration = false
        }
      });
    },
    /** 去首页 */
    goHomePage(){
      this.page.pageNum = 1
      this.seachBoundUser()
    },
    handleSizeChange(val){
      this.page.pageSize = val
      this.seachBoundUser()
    },
    handleCurrentChange(val){
      this.page.pageNum = val
      this.seachBoundUser()
    },
    /** 取消批量保存 */
    cancelbatchBoundEPUser(){
      this.editAllBtn = false;
      this.getBoundUserData('0',this.page);
    },
    /** 批量绑定用户 */
    savebatchBoundEPUser() {
      let params = [];
      let data = this.wechatdata.tabledata;
      data.forEach(item => {
        if (item.openid != "") {
          let obj = {}
          obj['cloudccuserid'] = item.cloudccuserid
          obj['loginname'] = item.loginname
          obj['openid'] = item.openid
          params.push(obj)
        }
      });

      EPWeChat.batchBoundEPUser(params).then((res) => {
        if (10000 == res.returnCode) {   
          this.editAllBtn = false;
          let status = this.activeName == "Unbound" ? "0" : "1";
          this.getBoundUserData(status,this.page);
          // 编辑全部成功
          this.$message.success(this.$i18n.t('c174'))
        }
      });
    },
  },
};
</script>
<style lang="scss">
.user-binding {
  margin: 15px 10px;
  .user-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .input-with-select {
      width: 280px;
      margin-right: 10px;
    }
  }
}
.user-tabs{
  .is-active{
    color: #2d6cfc !important;
  }
  .el-tabs__active-bar{
    background-color: #2d6cfc !important;
  }
}
.table_height{
  height: calc(100vh - 320px - 50px);
}
</style>